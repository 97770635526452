@layer components {
    .form-control {
      @apply appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 leading-tight border;
    }

    .form-control:focus {
        @apply outline-none bg-white border-gray-500;
    }

    .form-label {
        @apply block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2;
    }
  }